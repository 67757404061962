import React from 'react';

const NotFoundPage = () => (
  <div>
    <h1>Ukjent side</h1>
    <p>Denne siden eksisterer ikke.</p>
  </div>
);

export default NotFoundPage;
